<template>
  <select class="custom-select" :class="{'border-danger': hasError}" :value="value" @input="handleChange">
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  mixins: [inputMixin],
  computed: {
    options () {
      return [
        {
          value: 'customer',
          label: 'Client'
        },
        {
          value: 'merchant',
          label: 'Vendeur'
        }
      ]
    }
  }
}
</script>
